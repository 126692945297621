<template>
  <div id="BarcodeSearch"></div>
</template>
<script>
export default {
  name: "BarcodeSearch",
  data() {
    return {};
  },
  methods: {},
  mounted() {
    this.$router.push({ name: "Results" });
  }
};
</script>
<style scoped></style>
